<template>
  <div>
    <div class="row mx-auto" v-if="product == 'Malla ciclónica galvanizada'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA CICLONICA GALVANIZADA CON FORRO DE PVC</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Cercas y mallas #malla ciclónica con forro de pvc #cerca perimetral #cercas #mallas versátiles
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA CICLON GALV. CON PUAS</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #malla ciclón con púas #malla galvanizada #colocación de cercas #malla de alambre
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CERCA CICLONICA GALV.CON CONCERTINA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #colocación de cerca #vallas #venta de malla ciclónica #colocación de cerca de malla #venta de vallas
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/cercas/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white">CERCAS PARA CANCHAS</strong></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #malla ciclónica #cercas, mallas y cercas #cercos #venta de cercas #cerca para canchas deportivas
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/9.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CERCA TIPO MARCO SIN PUAS</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #colocación de malla ciclón #precio de malla ciclónica #cerca de colindancia #mallas #variedad de cercas y cercados
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/tjaula.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white">CERCA TIPO JAULAS</strong></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #malla ciclón #cerca de alambre #mallas metálicas #malla ciclónica con forro de pvc #paneles de malla #malla anti animales
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/cinta.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA CICLONICA CON CINTA PLASTICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #ceca de malla #puertas de malla ciclón #malla pcv #instalación de malla ciclónica #cercas de malla
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/cinta2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CINTA PLASTICA CON BROCHE</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #seguridad perimetral #costo de malla ciclónica #cerca para granjas #venta e instalación de malla ciclónica
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/cinta4.png')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >COLORES DE CINTA PLASTICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #cerca para ganado #malla simple torsión #suministro de cercas $malla delimitante
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Concertina'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/concertina/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA DOBLE LINEA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Concertina #concertina doble espiral #concertina doble navaja #concertina tipo arpón
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/concertina/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA COMBINADA CON CERCA ELECTRICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #concertina tipo bisturí #concertina doble línea #concertina de acero inoxidable  #espiral de seguridad
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/concertina/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA ARPON DOBLE NAVAJA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #espiral de seguridad militar #instalación de concertina #fabricante de concertina
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/concertina/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA NAVAJA RECTA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #suministro de concertina #protección de navajas #instalación de navajas #suministro de espirar de seguridad
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/concertina/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA SOBRE BARDA BASE DIAGONAL</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #protección tipo militar #colocación de concertina #instalación de concertina
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/concertina/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA CON MALLA SENCILLA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #venta de protección de navajas #espiral de navajas concertina #concertina para protección de arboles
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/1.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA TIPO ESTRELLA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #concertina galvanizada #venta de concertina #distribuidor de concertina
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/2.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA TIPO ARPON</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #instalación de concertina #venta de concertina #concertina para protección de postes
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/3.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA TIPO BISTURÍ</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #venta de espiral de seguridad #distribuidor de espiral de seguridad #instalación de espiral de seguridad
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div
      class="row mx-auto"
      v-if="product == 'Tubo Galvanizado' || product == 'Tuberia para cercas'"
    >
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tubo/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Uniformidad en las propiedades del acero</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tubo/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Alta ductilidad y rigidez combinada con malla ciclónica</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tubo/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Cumplimiento de normas internacionales de seguridad</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tubo/1.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Herrería y artesanías arquitectónicas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tubo/2.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Construcción de estructuras</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tubo/3.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Armado de vigas joist y Piezas industriales</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'PTR Galvanizado'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/1.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >De caracterizan por su alta resistencia a la intemperie</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/2.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Señalamientos viales, invernaderos, construcción de Cercos e industria ganadera</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/3.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Resistentes para marcos, armazones, puertas, barandas y soportes</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >De estructura metálicas ligeras para Reja de Acero</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ideales para puertas en Reja de Acero</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Recubierta galvanizada para maxima durabilidad</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/7.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Adaptables a soportes tipo Y y Diagonal</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >De alta rigides sobre superfices </strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Resistentes a perimetrales de tipo industrial</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Perfil sujetador'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Sujetador de plastico y/o la malla en el perfil sujetador.</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Función principal en los invernaderos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Casas sombras, viñedos e instalaciones del emparrillado de tutoreo</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Rigides y de alta durabilidad en sus angulos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >De facil reparación y sin desperdicio de cortes</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Adaptable para malla sombra y mosquitera</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Alambre Zig Zag'">
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Sujetador de plastico y/o la malla en el perfil sujetador.</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/7.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Función principal en los invernaderos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Casas sombras, viñedos e instalaciones del emparrillado de tutoreo</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/8.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Rigides y de alta durabilidad en sus angulos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >De facil reparación y sin desperdicio de cortes</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/perfil/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Adaptable para malla sombra y mosquitera</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="category == 'Reja de acero'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA DE ACERO CLASICA CON CONCERTINA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Reja panel #reja electrosoldada #reja verde
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/8.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >EURO MALLA CON TUBO REDONDO</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #reja #reja acero #reja de seguridad
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >IDEAL PARA ESTACIONAMIENTOS</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #reja de acero #panel de reja #reja perimetral
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/17.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA DE ACERO FLORENCIA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #reja Florencia #euroreja #panel #reja #reja irving
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/16.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA CONTEMPORANEA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #reja clásica #reja contemporánea #panel forte
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/forte.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA FORTE</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #malla electro soldada #reja forte #panel verde
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/exhibidora.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA PANEL EXHIBIDORA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #reja versátil #instalación de rejas# panel reja de alambre
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/19.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA CON CINTA PLASTICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #venta de rejas #reja de acero con placas #reja mesh
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA PERIMETRAL DE ALTA RESISTENCIA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #reja de fierro #rejas para casa #reja exhibidora
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Gaviones'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/gaviones/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >GAVIONES ESTRUCTURA TIPO MURO</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/gaviones/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >GAVIONES DE MALLA</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/gaviones/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >GAVIONES DE REJA PARA JARDINES</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/gaviones/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >GAVIONES PARA ESTRUCTURA ARQUITECTONICA</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/gaviones/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >ENREJADO DE GAVIONES</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/gaviones/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >GAVIONES DE REJA</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="category == 'Láminas'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/laminas/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Laminas resistentes para departamentos modulares</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/laminas/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Laminas Flexibles sobre domos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/laminas/3.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Combinación de Laminas para captación de luz natural</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/laminas/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Fácil manejo y distribución a gran escala</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/laminas/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Protección total sobre estructura de acero</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/laminas/7.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ideal para recubrimiento industrial</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Cercas electricas'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/cercaelectrica/6-2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CERCA ELECTRICA INDUSTRIAL</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CERCA ELECTRICA YONUSA #CERCA ELECTRICA INTEC #CERCAS ELECTRICAS #CERCA ELECTRICA GANADERA #CERCA ELECTRICA PRECIO
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/cercaelectrica/gan.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CERCA ELECTRICA GANADERA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CERCA ELECTRICA PARA NEGOCIOS #CERCA ELECTRICA PARA FABRICASCERCA ELECTRICA PARA COMERCIO #CERCA ELECTRICA DIGISEG #CERCO ELECTRICO
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/cercaelectrica/8.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CERCA ELECTRICA DIAGONAL</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CERCO ELECTRIFICADO #CERCO ELECTRICO CON ALARMA #CERCA CON ALARMA #CERCA ELECTRICA CASERA #CERCAS ELECTRIFICADAS
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/cercaelectrica/9-2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CERCA ELECTRICA RECIDENCIAL</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #VENTA DE EQUIPOS PARA CERCA ELECTRICA #INSTALACION DE CERCA ELECTRICA #INSTALACION DE CERCAS ELECTRICAS  #COSTO DE CERCA ELECTRICA
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/cercaelectrica/10-2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CERCA ELECTRIFICADA PARA CASA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CERCAS ELECTRIFICADAS #COLOCACION DE CERCAS ELECTRICAS #COLOCACION DE CERCAS ELECTRIFICADAS #CERCAS ENERGIZADAS  #VENTA E INSTALACION DE CERCAS ELECTRICAS
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/cercaelectrica/11.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >EQUIPO PARA CERCA ELECTRICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #VENTA DE CERCAS ENERGISADAS #INSTALACION DE CERCAS ENERGIZADAS #COLOCACION DE CERCAS ENERGIZADAS #CERCA ELECTRIFICADA #COMO INSTALAR UNA CERCA ELECTRICA.
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Cinta plastica'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallas/cinta.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA CICLONICA CON CINTA PLASTICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CINTA VINILICA #CINTA PARA MALLA CICLONICA #CINTA PARA REJA #CINTA PRIVACIDAD
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallas/cinta2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CINTA PLASTICA CON BROCHE</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CINTA PARA REJA #CINTA DE COLORES #CINTA DECORATIVA #CINTA EN PROTECCION 
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallas/cinta4.png')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >COLORES DE CINTA PLASTICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CINTILLA #CENTA DE CINTA PLASTICA  #VENTA DE CINTILLA #CINTA ROMPEVIENTOS
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/rejadeacero/19.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA DE ACERO CON CINTA PLASTICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #INSTALACION DE CINTA PLASTICA #COLOCACION DE CINTA PLASTICA #CINTA PLASTICA
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/rejadeacero/19.png')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CERCA CON CINTA PLASTICA PRIVACIDAD</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CINTA CUBRE REJAS #CINTA CUBRE CERCO #PRECIO CINTA CUBRE CERCO 
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/rejadeacero/21.png')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA CON CINTA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #CINTA EN REJA ACERO #CINTA EN HERRERIA Y TODO TIPO DE CERCOS
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Malla mosquitera'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/malla-mosquitera/10.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Protección contra mosquitos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/malla-mosquitera/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Cribado fino de materiales</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/malla-mosquitera/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Maleable y fácil de instalar</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/malla-mosquitera/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Impermeabilización</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/malla-mosquitera/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Componente en filtros y coladeras</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/malla-mosquitera/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Resistencia a la deformación y ruptura</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Metal desplegado'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/metaldesplegado/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >METAL DESPLEGADO</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/metaldesplegado/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >LAMINA PERFORADA</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/metaldesplegado/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA LÁMINA PERFORADA</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/metaldesplegado/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CHAPA PERFORADA</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/metaldesplegado/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >LAMINAS DE METAL PERFORADO</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/metaldesplegado/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >METAL PERFORADO</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Malla sombra'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallasombra/7.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA SOMBRA PARA TOLDOS</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Malla sombra #malla sombra arquitectónica #malla sombra Rachel 
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallasombra/13.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA SOMBRA ARQUITECTONICA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #veleros de malla sombra   #malla sombra para parques #malla sombre para contención de escombros
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallasombra/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA SOMBRA INVERNADERO</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #malla sombra para albercas #malla sombra para eventos #malla sombra para invernaderos
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallasombra/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA SOMBRA INVERNADERO</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #malla sombra para jardín #malla sombra para toldos  #malla sombra para vivero 
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallasombra/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA SOMBRA GANADERA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #malla sombre para protección de obras #malla sombra para ganado #malla sombra ganadera
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallasombra/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA SOMBRA DE MONOFILAMENTO</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #malla sombra para estacionamientos #malla sombra decorativa
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Multimalla'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/multmalla/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Empaquetada en Rollos, facil manejo</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/multmalla/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Protecciones para animales pequeños</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/multmalla/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Adaptable y Fácil de instalar</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/multmalla/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Jaulas y contenedores</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/multmalla/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Eficas y Costo eficiente </strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/multmalla/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Diferentes calibres de abertura</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Criba'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallacriba/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Para barandales arquitectónicos contemporáneos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallacriba/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ideas para exteriores anticorrosión</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallacriba/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Delimitación de áreas en estructura con puntas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallacriba/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Áreas o espacios de seguridad</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallacriba/8.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Cribado de granos o minerales</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallacriba/9.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Resistente en Jaulas para animales</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Poste ganadero'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/posteganadero/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Se introduce fácilmente en la tierra</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/posteganadero/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Resistencia extrema a la intemperie y el fuego</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/posteganadero/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Sustituye a los postes de madera</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/posteganadero/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Adaptabilidad a cualquier tipo de Malla y Cercado</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/posteganadero/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Instalación en ganadería, agricultura, avicultura, carreteras entre otras</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/posteganadero/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Piezas industriales y Armado</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Malla borreguera'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallaborreguera/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Absorción de impactos o cambios climatológicos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallaborreguera/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Delimitación de canchas </strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallaborreguera/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Corrales de borregos, cabras y pequeñas especies</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallaborreguera/1.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Malla Venadera</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallaborreguera/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Seguridad al cercar hacia adentro o hacia afuera</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallaborreguera/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Malla ganadera</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Poste de concreto'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/poste/1.png')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ideales para Cercas con Alambre de púas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/poste/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Poste cabezal diagonal con malla ciclón y Púa</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/poste/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Protección de Jardines y malla ciclónica</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/poste/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Corrales, Establos y potreros</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/poste/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Terrenos ganaderos Bardas  y Patios</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/poste/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Protección de terrenos agrícolas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Picos de seguridad'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/picos/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >PUNTAS SOBRE REJA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Picos de seguridad #picos para barda precio picos rhino 
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/picos/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >PICOS SOBRE BARDA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #picos de protección  #picos de herrería 
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/picos/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >PUNTAS SOBRE REJA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #picos para barda #picos para rejas #súper picos
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/picos/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >PICOS SOBRE BARDA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #picos para protección de árboles  #protección de picos 
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/picos/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MULTIPLES POCISIONES</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #puntas de acero #puntas #picos de alca seguridad
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/picos/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >TIPOS DE PICOS</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Para bardas #puntas protectoras  #protección para barda
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Malla hexagonal'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallahexagonal/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Casetas avícolas y  para animales pequeños</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallahexagonal/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Malla o cerco complementario</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallahexagonal/3.webp')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Recubrimiento galvanizado</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallahexagonal/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Protección de jardines y cercos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallahexagonal/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Malla hexagonal para jaulas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallahexagonal/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Enjarres y aislantes en construcción</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div
      class="row mx-auto"
      v-if="
        product == 'Alambre de púas' ||
        product == 'Alambre de púas de alta resistencia'
      "
    >
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Fácil instalación en madera</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ahorro en postes para formar el cerco</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Alambres pretensados sobre perimetrales fijos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre/7.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Excelente adaptabilidad a diferentes estructuras</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Cercos perimetrales de todo tipo</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Complemento de seguridad para cercos hechos con Rejas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Alambre galvanizado'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Peso y tamaño ideal que facilita su manejo y transporte</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Amarres en mallas Combinadas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Reparaciones provisionales</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >No genera desperdicio en cortes</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Instalaciones eléctricas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ahorro de postes en Cercados de Ganado</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Alambre galvanizado por tonelada'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/1.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Peso y tamaño ideal que facilita su manejo y transporte</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/2.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Amarres en mallas Combinadas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/3.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Reparaciones provisionales</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/4.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >No genera desperdicio en cortes</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Instalaciones eléctricas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ahorro de postes en Cercados de Ganado</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Malla rígida ondulada'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/alambre-galvanizado/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >En estructura tubular diferentes calibres</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallaondulada/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Invertida Vertical diseño romboide</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallaondulada/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Para barandales resistentes a la oxidación</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallaondulada/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Seguridad perimetral</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallaondulada/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >De uso Industrial automotriz</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallaondulada/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Mantiene una abertura uniforme de por vida</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div
      class="row mx-auto"
      v-if="
        category == 'Accesorios para cercas' && product != 'Picos de seguridad'
      "
    >
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/accesorios/24.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >COPLE CON OREJA</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/accesorios/23.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >TAPÓN SIMPLE</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/accesorios/33.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >ESPADA INTEGRAL</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/accesorios/31.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >ABRAZADERA DOBLE</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/accesorios/27.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >TAPÓN PÚA</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/accesorios/28.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >ABRAZADERA DE TENSION</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Malla de triple torsión'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tripletorsion/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Estabilidad estructural y flexible monolítica</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tripletorsion/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Protecci+on de areas dañadas por erosión</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tripletorsion/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Adaptable a diversos suelos y climas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tripletorsion/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ideal en estructura tipo Gavion</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tripletorsion/7.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Conservación de suelos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tripletorsion/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Control de ríos Protección estructural</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Lamina perforada'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/laminaperforada/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Perforación oblonga</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/laminaperforada/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Perforación redonda</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/laminaperforada/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Perforación Hexagonal</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/laminaperforada/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Perforación cuadrada para barandales</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
       <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/laminaperforada/7.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Lamina perforada para Naves industriales</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/laminaperforada/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Lamina perforada diseños minimalistas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Malla electrosoldada'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/electrosoldada/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Malla electrosoldada con marco metálico</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/electrosoldada/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Malla electrosoldada tipo cerca perimetral</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/electrosoldada/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Resistente a la tensión y gran calidad anti oxidación</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/electrosoldada/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Para refuerzo para el concreto en pisos y lozas</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/electrosoldada/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Diseños minimalistas y contemporáneos, fácil flexibilidad</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/electrosoldada/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Aplicaciones como casas, carreteras, túneles, puentes y pistas de aeropuertos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Malla triple nudo'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/triplenudo/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Cercos de alta seguridad y delimitaciones</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/triplenudo/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Difícil de escalar y cortar</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/triplenudo/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >En estructura con concertina</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/triplenudo/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Gran resistencia a los impactos y recargones</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/triplenudo/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Cercos perimetrales e interiores</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/triplenudo/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Cercos urbanos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Cerca de tubos'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadetubos/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Alta protección y seguridad</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadetubos/2.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Ideal para perímetros y estacionamientos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadetubos/3.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Rapidez de instalación modular</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadetubos/4.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Estética, durabilidad, fortaleza y confort</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadetubos/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Flexibilidad en diseños arquitectónicos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadetubos/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Facilidad ensamblaje en concreto</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>

    <div class="row mx-auto" v-if="product == 'Todos'">
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/mallas/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >MALLA CICLONICA GALVANIZADA CON FORRO DE PVC</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Cercas y mallas #malla ciclónica con forro de pvc #cerca perimetral #cercas #mallas versátiles
              </p></small
            >
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/concertina/concertina/6.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >CONCERTINA DOBLE LINEA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Concertina #concertina doble espiral #concertina doble navaja #concertina tipo arpón
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/ptr/1.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >De caracterizan por su alta resistencia a la intemperie</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/tubo/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Uniformidad en las propiedades del acero</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
              :src="require('../assets/rejadeacero/5.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >REJA DE ACERO CLASICA CON CONCERTINA</strong
              ></small
            >
            <small class="py-0 my-0" style="color: grey"
              ><p>
                #Reja panel #reja electrosoldada #reja verde
              </p></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/gaviones/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >GAVIONES ESTRUCTURA TIPO MURO</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/malla-mosquitera/10.jpeg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Protección contra mosquitos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/mallaborreguera/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >Absorción de impactos o cambios climatológicos</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <mdb-card narrow class="mb-4 elegant-color-dark">
          <mdb-view hover cascade>
            <mdb-card-image
               :src="require('../assets/metaldesplegado/1.jpg')"
              alt="Card image cap"
            ></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <small
              ><strong style="color: white"
                >METAL DESPLEGADO</strong
              ></small
            >
          </mdb-card-body>
          </mdb-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mdbView, mdbCard, mdbCardBody, mdbCardImage, mdbMask } from "mdbvue";
export default {
  props: {
    category: String,
    product: String,
  },
  components: {
    mdbView,
    mdbCard,
    mdbCardImage,
    mdbMask,
    mdbCardBody,
  },
};
</script>

<style>
.max {
  max-height: 12rem;
}
</style>